import React from 'react';
import './PrayerTimeBox.css'

interface PrayerTimeBoxProps {
  name: string;
  time: string;
  status?: string; // Optional status indicator
}

const PrayerTimeBox: React.FC<PrayerTimeBoxProps> = ({ name, time, status }) => {
  return (
    <div className={`prayer-time-box ${status}`}>
      <span className="prayer-name">{name}</span>
      <span className="prayer-time">{time}</span>
      {status && <span className="prayer-status">{status}</span>}
    </div>
  );
};

export default PrayerTimeBox;