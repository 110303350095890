import { useEffect } from 'react';
import './App.css';
import Clock from './components/Clock';
import CurrentDate from './components/CurrentDate';
import CurrentHijriDate from './components/CurrentHijriDate';
import { Logo } from './components/Logo';
import PrayerTimesList from './components/PrayerTimesList';
// Import the JSON data
import prayerTimesData from './prayerTimes.json';
import CheckInternet from './components/CheckInternet';

interface PrayerTimeData {
  name: string;
  time: string;
  status?: string;
}



function App() {
  const prayerTimes = prayerTimesData.prayerTimes as PrayerTimeData[];
  const SECOND_IN_MS = 1000;

  useEffect(() => {
    const interval = setInterval(() => {
      console.log('Logs every second');
    }, SECOND_IN_MS);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [])
  return (
    <div className="App">
      <header className="App-header">
        <CurrentDate />
        <Logo />
        <CurrentHijriDate />
      </header>
      <Clock />
      <PrayerTimesList prayerTimes={prayerTimes} />
      <CheckInternet/>
    </div>
  );
}

export default App;
