import React from 'react';
import PrayerTimeBox from './PrayerTimeBox'; // Adjust path if needed
import './PrayerTimesList.css'

interface PrayerTimesListProps {
  prayerTimes: { name: string; time: string; status?: string }[];
}

const PrayerTimesList: React.FC<PrayerTimesListProps> = ({ prayerTimes }) => {
  return (
    <div className="prayer-times-list">
      {prayerTimes.map((prayerTime) => (
        <PrayerTimeBox
          key={prayerTime.name}
          name={prayerTime.name}
          time={prayerTime.time}
          status={prayerTime.status}
        />
      ))}
    </div>
  );
};

export default PrayerTimesList;