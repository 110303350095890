import React, { useState, useEffect } from 'react';
import './CurrentDate.css'

interface CurrentDateProps {}

// Interface for representing date information
interface DateInfo {
  day: number;
  month: number;
  year: number;
  dayOfWeek: string;
}

const CurrentDate: React.FC<CurrentDateProps> = () => {
  const [currentDate, setCurrentDate] = useState<DateInfo | null>(null);

  useEffect(() => {
    const now = new Date();
    setCurrentDate({
      day: now.getDate(),
      month: now.getMonth() + 1, // Months are zero-indexed
      year: now.getFullYear(),
      dayOfWeek: now.toLocaleDateString('en-US', { weekday: 'long' }),
    });
  }, []);

  if (!currentDate) return null;

  return (
    <div className="current-date">
      <div className="big-date">
  <span className="day">{pad(currentDate.day,2)}</span>
  <span className="day-of-week">{currentDate.dayOfWeek}</span>
  <div className="date-details">
    <span className="month">{getMonthName(currentDate.month)}</span>
    <span className="year">{currentDate.year}</span>
  </div>
</div>

    
    </div>
    
  );
};

function pad(n: number, size: number) {
  let num = n.toString();
  while (num.length < size) num = "0" + num;
  return num;
}

// Helper function to get month name
function getMonthName(month: number): string {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  return months[month - 1];
}

export default CurrentDate;
