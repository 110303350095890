import React, { useState, useEffect } from 'react';
import './Clock.css'

const Clock: React.FC = () => {
  const [hours, setHours] = useState("");
  const [minutes, setMinutes] = useState("");
  const [meridiem, setMeridiem] = useState("");

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = new Date();
      const hours = now.getHours(); // Get current hour

      // Convert to 12-hour format and ensure leading zero
      setHours((hours % 12 || 12).toString().padStart(2, "0"));
      setMinutes(now.getMinutes().toString());
      setMeridiem(now.getHours() >= 12 ? "PM" : "AM");
    }, 1000); // Update every second

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="current-time">
      <span className="current-time-text">{hours}<span>:</span>{minutes.toString().padStart(2, "0")} {meridiem}</span>

    </div>
  );
};

export default Clock;