import React, { useEffect, useState } from 'react';


const CheckInternet: React.FC = () => {
  const [isOnline, setIsOnline] = useState<boolean>(true);

  useEffect(() => {
    const checkInternet = async () => {
      try {
        const response = await fetch('/');
        if (response.ok) {
          setIsOnline(true);
          if (window.location.href !== '/') {
            window.location.reload();
          }
        } else {
          setIsOnline(false);
        }
      } catch (error) {
        setIsOnline(false);
      }
    };

    //checkInternet();
    const interval = setInterval(checkInternet, 3600000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div>{isOnline ? "." : "x"}</div>
  );
};

export default CheckInternet;
